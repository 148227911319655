import {useOutletContext} from "react-router-dom";
import {ClientCtx} from "./ClientDetail";
import React from "react";
import {
  Alert,
  Box,
  CircularProgress,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import {useGetClientCircuitBreakers} from "../api-client/clients";
import {dateTimeFormat} from "../utils/date";

export const ClientCircuitBreakers = () => {
  const ctx = useOutletContext<ClientCtx>();
  const circuitBreakersQuery = useGetClientCircuitBreakers(ctx.client?.id ?? '');

  return (
    <Box>
      <Box sx={{ p: 3 }}>
        <Typography variant="h5">Circuit Breakers</Typography>
      </Box>

      {circuitBreakersQuery.isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : circuitBreakersQuery.data?.length !== 0 ? (
        <>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Service</TableCell>
                <TableCell>Fail Count</TableCell>
                <TableCell>Opened at</TableCell>
                <TableCell>Open until</TableCell>
                <TableCell>Failure</TableCell>
                <TableCell align="right">State</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {circuitBreakersQuery.data?.map(cb => (
                <TableRow key={cb.service}>
                  <TableCell>{cb.service}</TableCell>
                  <TableCell>{cb.failCount || '-'}</TableCell>
                  <TableCell>{cb.openedAt ? dateTimeFormat(cb.openedAt) :  '-'}</TableCell>
                  <TableCell>{cb.openUntil ? dateTimeFormat(cb.openUntil) :  '-'}</TableCell>
                  <TableCell>{cb.failure || '-'}</TableCell>
                  <TableCell align="right">
                    <Switch
                      size="small"
                      checked={cb.state === 'closed'}
                      color={cb.state === 'closed' ? 'success' : 'error'}
                      disabled
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      ) : (
        <Box sx={{ p: 3, pt: 0 }}>
          <Alert severity="info">No circuit breakers found.</Alert>
        </Box>
      )}
    </Box>
  );
};
