import { NavLink as NavLinkBase, NavLinkProps } from "react-router-dom";
import React from "react";
import classNames from "classnames";

export const NavLink = React.forwardRef<
  HTMLAnchorElement,
  NavLinkProps & React.RefAttributes<HTMLAnchorElement>
>((props, ref) => (
  <NavLinkBase
    ref={ref}
    {...props}
    className={({ isActive }) =>
      classNames(props.className as string, { "Mui-selected": isActive })
    }
  />
));
