import {
  Box,
  Button,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { UserDto } from "../api-client/model";

interface UserFormProps {
  user?: UserDto;
  onSubmit?: (value: UserFormValue) => void;
  passwordField?: boolean;
}

export enum UserLevel {
  Basic,
  TechUser,
  SuperAdmin
}

export interface UserFormValue {
  username: string;
  password: string;
  name: string;
  level: UserLevel;
  description?: string | null;
}

function toUserLevel(user: UserDto | undefined) {
  return user?.superAdmin ? UserLevel.SuperAdmin : user?.techUser ? UserLevel.TechUser : UserLevel.Basic;
}

export function UserForm({ user, onSubmit, passwordField }: UserFormProps) {
  const [username, setUsername] = useState(user?.username ?? "");
  const [name, setName] = useState(user?.name ?? "");
  const [level, setLevel] = useState(toUserLevel(user));
  const [description, setDescription] = useState(user?.description ?? "");
  const [password, setPassword] = useState("");

  useEffect(() => {
    setUsername(user?.username ?? "");
    setName(user?.name ?? "");
    setLevel(toUserLevel(user));
    setDescription(user?.description ?? "");
  }, [user]);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    onSubmit?.({
      username,
      name,
      level,
      description,
      password,
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Username"
        required
        variant="outlined"
        size="small"
        fullWidth
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        autoComplete="off"
        sx={{ mb: 3 }}
      />

      <TextField
        label="Name"
        required
        variant="outlined"
        size="small"
        fullWidth
        value={name}
        onChange={(e) => setName(e.target.value)}
        autoComplete="off"
        sx={{ mb: 3 }}
      />

      {passwordField ? (
        <TextField
          label="Password"
          required
          type="password"
          variant="outlined"
          size="small"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autoComplete="off"
          sx={{ mb: 3 }}
        />
      ) : null}

      <TextField
          label="Description"
          variant="outlined"
          size="small"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          autoComplete="off"
          sx={{ mb: 3 }}
      />

      <ToggleButtonGroup
        color="primary"
        value={level}
        exclusive
        onChange={(e, value) => setLevel(value)}
        sx={{ mb: 3 }}
      >
        <ToggleButton value={UserLevel.Basic}>Basic User</ToggleButton>
        <ToggleButton value={UserLevel.TechUser} color="warning">Technical User</ToggleButton>
        <ToggleButton value={UserLevel.SuperAdmin} color="error">Superadmin</ToggleButton>
      </ToggleButtonGroup>

      <Box>
        <Button type="submit" variant="contained">
          Save
        </Button>
      </Box>
    </form>
  );
}
