import {
  Box,
  Container,
  Typography
} from "@mui/material";
import React from "react";
import {Outlet} from "react-router-dom";

export const Jobs = () => {
  return (
    <Container sx={{ p: 4 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h4">Jobs</Typography>
      </Box>

      <Outlet />
    </Container>
  )
};
