import React from "react";
import { Container, Typography } from "@mui/material";

export function Home() {
  return (
    <Container sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
    </Container>
  );
}
