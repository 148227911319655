import {Outbox} from "../common/outbox/Outbox";
import {useParams} from "react-router-dom";
import React from "react";
import {BusinessCentralOrderQueue} from "./order-queue/BusinessCentralOrderQueue";
import {Grid} from "@mui/material";

type Props = {
  appName: string;
};

export function BusinessCentral({appName}: Props) {
  const params = useParams<{ clientId: string }>();
  const clientId = params.clientId as string;

  return (
    <>
      <h1>BusinessCentral</h1>

      <Grid container direction={'column'} spacing={5}>
        <Grid item>
          <Outbox clientId={clientId} application={appName}/>
        </Grid>

        <Grid item>
          <BusinessCentralOrderQueue clientId={clientId} application={appName}/>
        </Grid>
      </Grid>

    </>
  );
}
