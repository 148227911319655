import React from "react";
import {
  getGetClientsQueryKey,
  useAddClientUser,
  useDeleteClientUser,
  useGetClientUsers
} from "../api-client/clients";
import { useParams } from "react-router-dom";
import {
  Alert,
  Box,
  Button, CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton, InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {Add} from "@mui/icons-material";
import {GridTripleDotsVerticalIcon} from "@mui/x-data-grid";
import type {UserDto} from "../api-client/model";
import {useGetUsers} from "../api-client/users";
import {Controller, useForm} from "react-hook-form";
import {ConfirmDialog} from "../ui/ConfirmDialog";
import {useQueryClient} from "@tanstack/react-query";

export const ClientUsers: React.FC = () => {
  const params = useParams();
  const clientId = params.clientId as string;

  const [addUserDialogOpen, setAddUserDialogOpen] = React.useState(false);

  const clientUsersQuery = useGetClientUsers(clientId);
  const clientUsers = clientUsersQuery.data || [];

  if (clientUsersQuery.isInitialLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 1,
        px: 3
      }}>
        <Typography variant="h5">Users</Typography>

        <Box sx={{ display: "flex", alignItems: 'center' }}>
          <Button
            variant="contained"
            size="small"
            startIcon={<Add />}
            onClick={() => setAddUserDialogOpen(true)}
          >Add user</Button>

          <AddClientUser
            clientId={clientId}
            open={addUserDialogOpen}
            onClose={() => setAddUserDialogOpen(false)}
            onAdd={() => clientUsersQuery.refetch()}
            currentUsers={clientUsers}
          />
        </Box>
      </Box>

      {clientUsers.length === 0 ? (
        <Box sx={{ p: 3, pt: 0 }}>
          <Alert severity="info">No users found</Alert>
        </Box>
      ) : (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>Name</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {clientUsers.map((user) => (
                <UserRow user={user} key={user.id} clientId={clientId} onRemove={() => clientUsersQuery.refetch()} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

const UserRow: React.FC<{ user: UserDto; clientId: string; onRemove: () => void }> = ({ user, clientId, onRemove }) => {
  const queryClient = useQueryClient();
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const deleteMutation = useDeleteClientUser();

  async function remove() {
    await deleteMutation.mutateAsync({
      id: clientId,
      userId: user.id
    });

    queryClient.refetchQueries(getGetClientsQueryKey({ onlyUsers: true }));

    onRemove();

    setShowDeleteDialog(false);
  }

  return (
    <>
      <TableRow
        key={user.id}
        hover
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {user.username}
        </TableCell>
        <TableCell component="th" scope="row">
          {user.name}
        </TableCell>
        <TableCell align="right" sx={{ py: 0 }}>
          <IconButton
            size="small"
            onClick={e => setAnchorEl(e.target as HTMLBaseElement)}
          >
            <GridTripleDotsVerticalIcon fontSize="small" />
          </IconButton>

          <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            sx={{ py: 0 }}
            PaperProps={{ sx: { py: 0 } }}
          >
            <MenuItem onClick={() => setShowDeleteDialog(true)}>
              <ListItemText sx={{ color: 'error.main' }}>Delete</ListItemText>
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>

      <ConfirmDialog
        open={showDeleteDialog}
        onCancel={() => setShowDeleteDialog(false)}
        onConfirm={remove}
        confirmLabel="Delete"
        danger
        title="Delete user?"
      />
    </>
  );
}

type AddClientUserValue = {
  userId: string;
};

const AddClientUser: React.FC<{
  currentUsers: UserDto[];
  clientId: string;
  open: boolean;
  onClose: () => void;
  onAdd: () => void;
}> = ({ currentUsers, clientId, open, onClose, onAdd }) => {
  const queryClient = useQueryClient();
  const getUsersQuery = useGetUsers();
  const addClientUserMutation = useAddClientUser();
  const { handleSubmit, control, reset } = useForm<AddClientUserValue>({
    defaultValues: {
      userId: ''
    }
  });

  const availableUsers = getUsersQuery.data?.filter(user => !currentUsers.find(u => u.id === user.id)) || [];

  async function onSubmit(value: AddClientUserValue) {
    await addClientUserMutation.mutateAsync({
      id: clientId,
      data: {
        userId: value.userId
      }
    });

    queryClient.refetchQueries(getGetClientsQueryKey({ onlyUsers: true }));

    onAdd();
    onClose();
    reset();
  }

  function handleClose() {
    reset();
    onClose();
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add client user</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{mt: 1, width: 320}}>
            <FormControl fullWidth size="small">
              <InputLabel id="addClientUserSelect" required>User</InputLabel>
              <Controller
                name="userId"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    labelId="addClientUserSelect"
                    label="User"
                    value={field.value}
                    onChange={e => field.onChange(e.target.value)}
                  >
                    <MenuItem value="">-</MenuItem>
                    {availableUsers?.map(user => (
                      <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>

            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
              disabled={addClientUserMutation.isLoading}
            >Add</Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

