import {Box, Dialog, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import React from "react";
import {useGetClients} from "../api-client/clients";
import {useNavigate} from "react-router-dom";
import {ClientDto} from "../api-client/model";

type Props = {
  open: boolean;
  onClose: () => void;
}

export const ClientSelectDialog: React.FC<Props> = ({ open, onClose }) => {
  const navigate = useNavigate();
  const clientsQuery = useGetClients({}, {
    query: {
      enabled: open,
    },
  });

  function manageClient(client: ClientDto) {
    navigate(`/manage/${client.id}`);
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={{ p: 3 }}>
        <Typography variant="h5">Choose client</Typography>
      </Box>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Client</TableCell>
            <TableCell>Config</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {clientsQuery.data?.map((client) => (
          <TableRow key={client.id} hover onClick={() => manageClient(client)} sx={{ cursor: 'pointer' }}>
            <TableCell>{client.name}</TableCell>
            <TableCell>{client.configPath}</TableCell>
          </TableRow>
        ))}
        </TableBody>
      </Table>
    </Dialog>
  );
};
