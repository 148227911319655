import React, { useEffect, useState } from "react";
import { SmartQueueMessageDto } from "../../../api-client/model";
import {
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { dateTimeFormat } from "../../../utils/date";
import { ConfirmDialog } from "../../../ui/ConfirmDialog";
import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";

export interface MessageQueueFilterParams {
  status?: string;
  processedDateFrom?: string;
  processedDateTo?: string;
  page?: number;
  size?: number;
}

export const DEFAULT_FILTER: MessageQueueFilterParams = {
  status: "all",
  page: 0,
  size: 10,
};

interface Column<T> {
  name: string;
  render: (item: T) => React.ReactNode;
  sx?: SxProps;
}

type Props<T extends SmartQueueMessageDto> = {
  clientId: string;
  messages: T[];
  refetch: () => unknown;
  isLoading: boolean;
  count?: number;
  retryMessage: (message: T) => unknown;
  filter: MessageQueueFilterParams;
  onFilter: (filter: MessageQueueFilterParams) => unknown;
  extraColumns?: Column<T>[];
  title?: string;
};

export function MessageQueue<T extends SmartQueueMessageDto>({
  messages,
  refetch,
  retryMessage,
  isLoading,
  count,
  filter,
  onFilter,
  extraColumns,
  title
}: Props<T>) {
  const [messageToRetry, setMessageToRetry] = useState<T | null>(null);

  async function retry(message: T) {
    await retryMessage(message);
    setMessageToRetry(null);
    await refetch();
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {title ?? 'Fronta zpráv'}
      </Typography>

      <Toolbar sx={{ mb: 1 }} disableGutters>
        <FormControl>
          <FormLabel>Stav</FormLabel>
          <RadioGroup
            row
            name="status"
            value={filter.status}
            onChange={(e, status) => {
              onFilter({
                status,
                page: 0,
              });
            }}
          >
            <FormControlLabel value="all" control={<Radio />} label="Vše" />
            <FormControlLabel
              value="waiting"
              control={<Radio />}
              label="Čekající"
            />
            <FormControlLabel
              value="failing"
              control={<Radio />}
              label="Problematické"
            />
            <FormControlLabel
              value="failed"
              control={<Radio />}
              label="Chybné"
            />
            <FormControlLabel
              value="processed"
              control={<Radio />}
              label="Zpracované"
            />
          </RadioGroup>
        </FormControl>
      </Toolbar>

      <Paper>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Zdroj</TableCell>
                <TableCell>Operace</TableCell>
                {extraColumns?.map((column, i) => (
                  <TableCell key={i}>{column.name}</TableCell>
                ))}
                <TableCell>Vytvořeno</TableCell>
                <TableCell>Zpracováno</TableCell>
                <TableCell>Stav</TableCell>
                <TableCell>Problém</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading &&
                new Array(filter.size ?? 0).fill(0).map((_, i) => (
                  <TableRow key={i} sx={{ height: 34.3 }}>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                ))}
              {messages.map((message) => (
                <TableRow key={message.id}>
                  <TableCell>{message.sourceId}</TableCell>
                  <TableCell>{message.sourceSystem}</TableCell>
                  <TableCell>{message.operation}</TableCell>
                  {extraColumns?.map((column, i) => (
                    <TableCell key={i} sx={column.sx}>
                      {column.render(message)}
                    </TableCell>
                  ))}
                  <TableCell>{dateTimeFormat(message.occurredOn)}</TableCell>
                  <TableCell>
                    {message.processedDate
                      ? dateTimeFormat(message.processedDate)
                      : "-"}
                  </TableCell>
                  <TableCell>
                    {message.processedDate ? (
                      <Chip
                        sx={{ height: 20, lineHeight: 1 }}
                        size="small"
                        label={message.failed ? "Chyba" : "Zpracováno"}
                        color={message.failed ? "error" : "success"}
                      />
                    ) : (
                      <Chip
                        sx={{ height: 20, lineHeight: 1 }}
                        size="small"
                        label="Čeká na zpracování"
                        color="primary"
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {message.reason?.substring(0, 255) || "-"}
                  </TableCell>
                  <TableCell sx={{ py: 0, textAlign: "right" }}>
                    {message.failed && (
                      <Button
                        size="small"
                        onClick={() => setMessageToRetry(message)}
                      >
                        Opakovat
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          count={count ?? -1}
          page={filter.page ?? 0}
          onPageChange={(e, page) => onFilter({ page })}
          rowsPerPage={filter.size ?? -1}
          onRowsPerPageChange={(e) =>
            onFilter({
              size: parseInt(e.target.value),
              page: 0,
            })
          }
        />
      </Paper>

      <ConfirmDialog
        open={messageToRetry != null}
        title="Opakované zpracování"
        onCancel={() => setMessageToRetry(null)}
        onConfirm={() => retry(messageToRetry!)}
      >
        Zařadit zprávu{" "}
        <strong>
          {messageToRetry?.sourceId} {messageToRetry?.operation}
        </strong>{" "}
        k opakovanému zpracování?
      </ConfirmDialog>
    </>
  );
}
