/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Dirigos API
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  OrionEdiPackageQueueMessageDto,
  OrionEdiPackageQueueDto,
  GetOrionEdiPackageQueueMessagesParams,
} from "./model";
import { http } from "../http";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const orionEdiPackageRetryMessage = (
  client: string,
  messageId: string,
  options?: SecondParameter<typeof http>
) => {
  return http<OrionEdiPackageQueueMessageDto>(
    {
      url: `/api/v1/clients/${client}/orionedi/package-queue/${messageId}/retry`,
      method: "post",
    },
    options
  );
};

export type OrionEdiPackageRetryMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof orionEdiPackageRetryMessage>>
>;

export type OrionEdiPackageRetryMessageMutationError = unknown;

export const useOrionEdiPackageRetryMessage = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof orionEdiPackageRetryMessage>>,
    TError,
    { client: string; messageId: string },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof orionEdiPackageRetryMessage>>,
    { client: string; messageId: string }
  > = (props) => {
    const { client, messageId } = props ?? {};

    return orionEdiPackageRetryMessage(client, messageId, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof orionEdiPackageRetryMessage>>,
    TError,
    { client: string; messageId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const getOrionEdiPackageQueueMessages = (
  client: string,
  params?: GetOrionEdiPackageQueueMessagesParams,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<OrionEdiPackageQueueDto>(
    {
      url: `/api/v1/clients/${client}/orionedi/package-queue`,
      method: "get",
      params,
      signal,
    },
    options
  );
};

export const getGetOrionEdiPackageQueueMessagesQueryKey = (
  client: string,
  params?: GetOrionEdiPackageQueueMessagesParams
) => [
  `/api/v1/clients/${client}/orionedi/package-queue`,
  ...(params ? [params] : []),
];

export type GetOrionEdiPackageQueueMessagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOrionEdiPackageQueueMessages>>
>;
export type GetOrionEdiPackageQueueMessagesQueryError = unknown;

export const useGetOrionEdiPackageQueueMessages = <
  TData = Awaited<ReturnType<typeof getOrionEdiPackageQueueMessages>>,
  TError = unknown
>(
  client: string,
  params?: GetOrionEdiPackageQueueMessagesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOrionEdiPackageQueueMessages>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetOrionEdiPackageQueueMessagesQueryKey(client, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getOrionEdiPackageQueueMessages>>
  > = ({ signal }) =>
    getOrionEdiPackageQueueMessages(client, params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getOrionEdiPackageQueueMessages>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!client,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
