/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Dirigos API
 * OpenAPI spec version: 1.0
 */
import { useMutation } from "@tanstack/react-query";
import type {
  UseMutationOptions,
  MutationFunction,
} from "@tanstack/react-query";
import type { ChangePasswordResultDto, ChangePasswordDto } from "./model";
import { http } from "../http";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const changePassword = (
  changePasswordDto: ChangePasswordDto,
  options?: SecondParameter<typeof http>
) => {
  return http<ChangePasswordResultDto>(
    {
      url: `/api/v1/user/change-password`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: changePasswordDto,
    },
    options
  );
};

export type ChangePasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof changePassword>>
>;
export type ChangePasswordMutationBody = ChangePasswordDto;
export type ChangePasswordMutationError = unknown;

export const useChangePassword = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changePassword>>,
    TError,
    { data: ChangePasswordDto },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof changePassword>>,
    { data: ChangePasswordDto }
  > = (props) => {
    const { data } = props ?? {};

    return changePassword(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof changePassword>>,
    TError,
    { data: ChangePasswordDto },
    TContext
  >(mutationFn, mutationOptions);
};
