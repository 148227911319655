import { Navigate, Outlet, useLocation } from "react-router-dom";
import {
  Box,
} from "@mui/material";
import { AppBar } from "../components/AppBar";
import React from "react";
import { useAuthentication } from "./AuthProvider";
import {QuickClientSelect} from "../components/QuickClientSelect";

export function ProtectedRoute() {
  const auth = useAuthentication();

  if (!auth.isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return (
    <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <AppBar />
      <Box sx={{ display: "flex", backgroundColor: "#fff", flex: 1 }}>
        <QuickClientSelect />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) => theme.palette.grey[100],
            flexGrow: 1,
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}
