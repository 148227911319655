import {useOutletContext} from "react-router-dom";
import {ClientCtx} from "./ClientDetail";
import {filesize} from "filesize";
import React from "react";
import {Box, CircularProgress, useTheme} from "@mui/material";
import {useGetClientStorageInfo} from "../api-client/clients";
import {LineChart} from "@mui/x-charts";
import {dateTimeFormat} from "../utils/date";

export const ClientStorageInfo = () => {
  const ctx = useOutletContext<ClientCtx>();
  const storageInfoQuery = useGetClientStorageInfo(ctx.client?.id ?? '');
  const theme = useTheme();

  return (
    <Box sx={{ p: 3 }}>
      {storageInfoQuery.isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : storageInfoQuery.data && (
        <>
          <LineChart
            series={[
              {
                data: storageInfoQuery.data?.map(x => x.dbSize) ?? [],
                label: 'DB Size',
                id: 'dbSize',
                color: theme.palette.success.light,
                valueFormatter: (v) => filesize(v ?? 0),
                area: true,
              },
            ]}
            xAxis={[{
              scaleType: 'band',
              data: storageInfoQuery.data?.map(x => dateTimeFormat(x.createdAt)) ?? [],
            }]}
            yAxis={[{
              scaleType: 'linear',
              valueFormatter: (v) => filesize(v ?? 0),
            }]}
            height={300}
          />

          <LineChart
            series={[
              {
                data: storageInfoQuery.data?.map(x => x.clientDirSize) ?? [],
                label: 'Client Directory Size',
                id: 'clientDir',
                color: theme.palette.info.light,
                valueFormatter: (v) => filesize(v ?? 0),
                area: true
              },
            ]}
            xAxis={[{
              scaleType: 'band',
              data: storageInfoQuery.data?.map(x => dateTimeFormat(x.createdAt)) ?? [],
            }]}
            yAxis={[{
              scaleType: 'linear',
              valueFormatter: (v) => filesize(v ?? 0),
            }]}
            height={300}
          />
        </>
      )}
    </Box>
  );
};
