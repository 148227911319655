import { Alert, Container, Paper, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useChangePassword } from "../api-client/user";

export function UserChangePassword() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");

  const changePassword = useChangePassword();

  function passwordsMatch() {
    return passwordCheck.length ? newPassword === passwordCheck : true;
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!passwordsMatch() || changePassword.isLoading) {
      return;
    }

    const result = await changePassword.mutateAsync({
      data: {
        currentPassword,
        newPassword,
      },
    });

    setCurrentPassword("");
    setNewPassword("");
    setPasswordCheck("");
  }

  return (
    <Container sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Change password
      </Typography>

      <Paper sx={{ p: 3 }}>
        {changePassword.isLoading ? (
          <Alert severity="success" sx={{ mb: 3 }}>
            Password changed
          </Alert>
        ) : null}
        {changePassword.isError ? (
          <Alert severity="error" sx={{ mb: 3 }}>
            Current password is not valid
          </Alert>
        ) : null}

        <form onSubmit={handleSubmit}>
          <TextField
            label="Current password"
            required
            type="password"
            variant="outlined"
            size="small"
            fullWidth
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            autoComplete="off"
            sx={{ mb: 3 }}
          />

          <TextField
            label="Password"
            required
            type="password"
            variant="outlined"
            size="small"
            fullWidth
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            autoComplete="off"
            sx={{ mb: 3 }}
          />

          <TextField
            label="Password check"
            required
            type="password"
            variant="outlined"
            size="small"
            fullWidth
            value={passwordCheck}
            onChange={(e) => setPasswordCheck(e.target.value)}
            autoComplete="off"
            sx={{ mb: 3 }}
            error={!passwordsMatch()}
            helperText={passwordsMatch() ? null : "Passwords do not match"}
          />

          <LoadingButton
            type="submit"
            variant="contained"
            loading={changePassword.isSuccess}
          >
            Change
          </LoadingButton>
        </form>
      </Paper>
    </Container>
  );
}
