export const COLORS = [
    {
        name: 'purple',
        color: '#673AB7',
    },
    {
        name: 'cyan',
        color: '#00BCD4',
    },
    {
        name: 'teal',
        color: '#009688',
    },
    {
        name: 'green',
        color: '#8BC34A',
    },
    {
        name: 'lime',
        color: '#CDDC39',
    },
    {
        name: 'amber',
        color: '#FFC107',
    },
    {
        name: 'red',
        color: '#F44336',
    },
    {
        name: 'pink',
        color: '#E91E63',
    },
]
