import React from "react";
import {Outlet, Route, Routes} from "react-router-dom";
import {OrionPackageQueue} from "./package-queue/OrionPackageQueue";

export const OrionEdi: React.FC = () => {
  return (
    <>
      <h1>OrionEdi</h1>

      <Outlet />

      <Routes>
        <Route index element={<OrionPackageQueue />} />
      </Routes>
    </>
  );
};
