import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  Typography,
} from "@mui/material";

type Props = {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
  confirmLabel?: string;
  danger?: boolean;
};

export const ConfirmDialog: React.FC<React.PropsWithChildren<Props>> = ({
  open,
  onConfirm,
  onCancel,
  title,
  children,
  danger,
  confirmLabel
}) => {
  return (
    <Dialog open={open}>
      <Box sx={{ minWidth: 320 }}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={() => onCancel()}>Cancel</Button>
          <Button onClick={() => onConfirm()} color={danger ? 'error' : 'primary'}>{confirmLabel || 'Confirm'}</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
