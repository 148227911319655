import { Container, Paper, Typography } from "@mui/material";
import { UserForm, UserFormValue, UserLevel } from "./UserForm";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useCreateUser } from "../api-client/users";

export function NewUser() {
  const navigate = useNavigate();
  const createUserMutation = useCreateUser();

  function handleSubmit({level, ...value}: UserFormValue) {
    createUserMutation
      .mutateAsync({
        data: {
          ...value,
          superAdmin: level === UserLevel.SuperAdmin,
          techUser: level === UserLevel.TechUser,
        }
      })
      .then((data) => {
        if (data.id) {
          navigate(`/users/${data.id}`);
        }
      });
  }

  return (
    <Container sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Create User
      </Typography>

      <Paper sx={{ p: 3 }}>
        <UserForm onSubmit={handleSubmit} passwordField />
      </Paper>
    </Container>
  );
}
