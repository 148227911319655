import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  getGetClientsQueryKey,
  useCreateClient,
  useGetAvailableClientConfigs,
} from "../api-client/clients";
import { useQueryClient } from "@tanstack/react-query";
import {COLORS} from "../theme";

export function NewClient() {
  const navigate = useNavigate();
  const [code, setCode] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [configPath, setConfigPath] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const queryClient = useQueryClient();

  // queries
  const availableConfigsQuery = useGetAvailableClientConfigs();
  const availableConfigs = availableConfigsQuery.data || [];

  // mutations
  const createClientMutation = useCreateClient();

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const response = await createClientMutation.mutateAsync({
      data: {
        code,
        name,
        description,
        configPath,
        color: COLORS[Math.floor(Math.random() * COLORS.length)].name,
        schedulerEnabled: false,
      },
    });

    queryClient.invalidateQueries({ queryKey: getGetClientsQueryKey() });

    navigate(`/clients/${response.id}`);
  }

  return (
    <Container sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Create client
      </Typography>

      <Paper
        component="form"
        onSubmit={handleSubmit}
        sx={{ display: "flex", flexDirection: "column", p: 3 }}
        variant="outlined"
      >
        <FormControl size="small" required fullWidth sx={{ mb: 3 }}>
          <InputLabel>Config</InputLabel>
          <Select
            value={configPath}
            label="Config"
            onChange={(e) => setConfigPath(e.target.value)}
          >
            {availableConfigs.map((path) => (
              <MenuItem key={path} value={path}>
                {path}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="Code"
          required
          variant="outlined"
          size="small"
          fullWidth
          value={code}
          onChange={(e) => setCode(e.target.value)}
          autoComplete="off"
          helperText="Kódové označení integrace"
          sx={{ mb: 3 }}
        />

        <TextField
          label="Name"
          required
          variant="outlined"
          size="small"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          autoComplete="off"
          sx={{ mb: 3 }}
        />

        <TextField
          label="Description"
          variant="outlined"
          size="small"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          autoComplete="off"
          sx={{ mb: 3 }}
        />

        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Button type="submit" variant="contained">
            Create
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}
