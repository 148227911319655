import React, {useEffect, useMemo} from "react";
import {
  Box,
  Button, Chip,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Tooltip,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import {Add, Circle} from "@mui/icons-material";
import { useGetClients } from "../api-client/clients";
import {filesize} from "filesize";
import {ClientDto} from "../api-client/model";
import {ClientIcon} from "../ui/ClientIcon";

export function Clients() {
  const navigate = useNavigate();
  const clientsQuery = useGetClients();
  const clients = clientsQuery.data || [];

  return (
    <Container sx={{ p: 4 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h4">Clients</Typography>

        <Button
          variant="contained"
          size="small"
          component={Link}
          to="new"
          startIcon={<Add />}
        >
          Create Client
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Services</TableCell>
              <TableCell>Scheduler</TableCell>
              <TableCell align="right">Client Dir Size</TableCell>
              <TableCell align="right">DB Size</TableCell>
              <TableCell width={10} />
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((client) => <ClientRow client={client} key={client.id} />)}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

const ClientRow: React.FC<{ client: ClientDto }> = ({ client }) => {
  const navigate = useNavigate();
  const services = useMemo(() => Array.from(new Set(client.services.map(x => x.application))).sort(), [client.services]);

  return (
    <TableRow
      hover
      sx={{
        cursor: 'pointer',
        opacity: client.schedulerEnabled ? 1 : 0.5,
      }}
      onClick={() => navigate(client.id)}
    >
      <TableCell>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <ClientIcon client={client} isActive size={20} />
          {client.name}
        </Box>
      </TableCell>
      <TableCell>
        {client.code}
      </TableCell>
      <TableCell sx={{ py: 0 }}>
        {services.map(svc => <Chip label={svc} key={svc} size="small" sx={{ mr: 1, height: 'auto' }} />)}
      </TableCell>
      <TableCell>
        {client.schedulerEnabled ? <Typography fontSize="small" color="success">Enabled</Typography> : <Typography fontSize="small" color="error">Disabled</Typography>}
      </TableCell>
      <TableCell align="right">
        <Typography fontSize="small" color={client.clientDirSize && client.clientDirSize > 15_000_000_000 ? 'warning.light' : 'default'}>
          {filesize(client.clientDirSize ?? 0, { exponent: 3 })}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography fontSize="small" color={client.dbSize && client.dbSize > 30_000_000_000 ? 'warning.light' : 'default'}>
          {filesize(client.dbSize ?? 0, { exponent: 3 })}
        </Typography>
      </TableCell>
      <TableCell align="right">
        {(client.circuitBreakers?.closed ?? 0) > 0 && (
          <Box sx={{ display: 'flex', fontSize: 14 }}>
            <Tooltip title={`${client.circuitBreakers?.closed} of ${client.circuitBreakers?.total} breakers closed`}>
              <Circle
                fontSize="inherit"
                color={client.circuitBreakers?.closed === client.circuitBreakers?.total ? 'success' : 'error'}
              />
            </Tooltip>
          </Box>
        )}
      </TableCell>
    </TableRow>
  );
}
