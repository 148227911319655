import {useParams} from "react-router-dom";
import {OrionEdi} from "../apps/orionedi/OrionEdi";
import {SuperFaktura} from "../apps/superfaktura/SuperFaktura";
import {Emorfic} from "../apps/emorfic/Emorfic";
import {Navision} from "../apps/navision/Navision";
import React, {useContext} from "react";
import {ClientContext} from "./ClientManager";
import {BusinessCentral} from "../apps/businesscentral/BusinessCentral";

export const ClientService = () => {
    const client = useContext(ClientContext);
    const params = useParams<{ service: string  }>();
    const service = client?.services.find(x => x.name.toLowerCase() === params.service);

    switch (service?.application) {
        case 'BusinessCentral':
            return <BusinessCentral appName={service.name.toLowerCase()} />;
        case 'OrionEdi':
            return <OrionEdi />;
        case 'SuperFaktura':
            return <SuperFaktura />;
        case 'Emorfic':
            return <Emorfic appName={service.name.toLowerCase()} />;
        case 'Navision':
            return <Navision />;
        default:
            return null;
    }
};
