import React, {useEffect} from "react";

export function getElapsedTime(startDate: Date, endDate: Date): string {
  const seconds = (endDate.getTime() - startDate.getTime()) / 1000;
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const hourString = hours > 0 ? `${hours}h` : ''
  const minuteString = `${minutes}min`
  const secondString = `${remainingSeconds}s`;

  if (hours > 0) {
    return `${hourString} ${minuteString} ${secondString && ` ${secondString}`}`
  } else if (!hours && minutes > 0) {
    return `${minuteString} ${secondString}`;
  }

  return secondString;
}

export const ElapsedTime = ({ startDate, endDate }: { startDate: Date | string, endDate?: Date | string | null }) => {
  const start = typeof startDate === 'string' ? new Date(startDate) : startDate;
  const end = typeof endDate === 'string' ? new Date(endDate) : endDate;
  const [elapsed, setElapsed] = React.useState<string>(getElapsedTime(start, end ?? new Date()));

  useEffect(() => {
    if (!end) {
      const interval = setInterval(() => {
        setElapsed(getElapsedTime(start, new Date()));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [start, end]);

  return (
    <span>{elapsed}</span>
  );
}
