import React, { useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Container,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useLogin } from "../api-client/users";
import { useAuthentication } from "../auth";

export function Login() {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const auth = useAuthentication();

  const loginMutation = useLogin();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitted(true);

    if (!username || !password) {
      return;
    }

    setInvalidCredentials(false);

    try {
      const result = await loginMutation.mutateAsync({
        data: { username, password },
      });
      setInvalidCredentials(!result.success);

      if (result.success) {
        auth.refresh();
        navigate("/");
      } else {
        setPassword("");
      }
    } catch (error) {
      setPassword("");
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{
          mt: 8,
          display: "flex",
          flexDirection: "column",
          mx: "auto",
          alignItems: "center",
        }}
      >
        <Box sx={{ m: 1 }}>
          <img src="./logo.svg" alt="logo" style={{ width: "100px" }} />
        </Box>

        <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
          Sign In
        </Typography>

        {invalidCredentials ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            Invalid credentials
          </Alert>
        ) : null}
        {loginMutation.isError ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            Something went wrong
          </Alert>
        ) : null}

        <TextField
          label="Username"
          required
          fullWidth
          size="small"
          autoFocus
          variant="outlined"
          sx={{ mb: 2 }}
          value={username}
          error={submitted && !username}
          onChange={(e) => setUsername(e.target.value)}
        />

        <TextField
          label="Password"
          required
          fullWidth
          size="small"
          variant="outlined"
          type="password"
          sx={{ mb: 2 }}
          value={password}
          error={submitted && !password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <LoadingButton
          type="submit"
          variant="contained"
          fullWidth
          loading={loginMutation.isLoading}
          sx={{ my: 2 }}
        >
          Login
        </LoadingButton>

        <Typography variant="body2" color="text.secondary" align="center">
          &copy;{" "}
          <Link color="inherit" href="https://www.emorfiq.com/" target="_blank">
            Emorfiq
          </Link>{" "}
          {new Date().getFullYear()}
        </Typography>
      </Box>
    </Container>
  );
}
