import {Box, Dialog, DialogContent, DialogTitle} from "@mui/material";
import React from "react";
import {useUpdateJob} from "../api-client/scheduler";
import {SchedulerJobForm, SchedulerJobFormValue} from "./SchedulerJobForm";
import {SchedulerJobDto} from "../api-client/model";

type Props = {
  open: boolean;
  onClose: () => void;
  clientId: string;
  job: SchedulerJobDto;
  onUpdated: () => void;
};

export const UpdateSchedulerJob: React.FC<Props> = ({ clientId, job, open, onClose, onUpdated }) => {
  const updateMutation = useUpdateJob();
  const initialValue: SchedulerJobFormValue = {
    name: job?.name ?? '',
    cron: job?.cron ?? '',
    args: job?.args ?? '',
    historyLimit: job?.historyLimit ?? 50
  };

  async function create(data: SchedulerJobFormValue) {
    await updateMutation.mutateAsync({
      id: job.id,
      data: {
        clientId,
        ...data
      }
    });

    onClose();
    onUpdated();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Box>
        <DialogTitle>Configure scheduler job</DialogTitle>
        <DialogContent>
          <SchedulerJobForm initialValue={initialValue} onSubmit={create} />
        </DialogContent>
      </Box>
    </Dialog>
  )
};
