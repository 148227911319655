/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Dirigos API
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  SchedulerJobDto,
  GetSchedulerJobsParams,
  SchedulerJobInputDto,
  ConfigureSchedulerJobDto,
} from "./model";
import { http } from "../http";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const getSchedulerJobs = (
  params?: GetSchedulerJobsParams,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<SchedulerJobDto[]>(
    { url: `/api/v1/scheduler/jobs`, method: "get", params, signal },
    options
  );
};

export const getGetSchedulerJobsQueryKey = (
  params?: GetSchedulerJobsParams
) => [`/api/v1/scheduler/jobs`, ...(params ? [params] : [])];

export type GetSchedulerJobsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSchedulerJobs>>
>;
export type GetSchedulerJobsQueryError = unknown;

export const useGetSchedulerJobs = <
  TData = Awaited<ReturnType<typeof getSchedulerJobs>>,
  TError = unknown
>(
  params?: GetSchedulerJobsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSchedulerJobs>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetSchedulerJobsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSchedulerJobs>>
  > = ({ signal }) => getSchedulerJobs(params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getSchedulerJobs>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export const createJob = (
  schedulerJobInputDto: SchedulerJobInputDto,
  options?: SecondParameter<typeof http>
) => {
  return http<SchedulerJobDto>(
    {
      url: `/api/v1/scheduler/jobs`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: schedulerJobInputDto,
    },
    options
  );
};

export type CreateJobMutationResult = NonNullable<
  Awaited<ReturnType<typeof createJob>>
>;
export type CreateJobMutationBody = SchedulerJobInputDto;
export type CreateJobMutationError = unknown;

export const useCreateJob = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createJob>>,
    TError,
    { data: SchedulerJobInputDto },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createJob>>,
    { data: SchedulerJobInputDto }
  > = (props) => {
    const { data } = props ?? {};

    return createJob(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof createJob>>,
    TError,
    { data: SchedulerJobInputDto },
    TContext
  >(mutationFn, mutationOptions);
};
export const updateJob = (
  id: number,
  schedulerJobInputDto: SchedulerJobInputDto,
  options?: SecondParameter<typeof http>
) => {
  return http<SchedulerJobDto>(
    {
      url: `/api/v1/scheduler/jobs/${id}`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: schedulerJobInputDto,
    },
    options
  );
};

export type UpdateJobMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateJob>>
>;
export type UpdateJobMutationBody = SchedulerJobInputDto;
export type UpdateJobMutationError = unknown;

export const useUpdateJob = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateJob>>,
    TError,
    { id: number; data: SchedulerJobInputDto },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateJob>>,
    { id: number; data: SchedulerJobInputDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateJob(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateJob>>,
    TError,
    { id: number; data: SchedulerJobInputDto },
    TContext
  >(mutationFn, mutationOptions);
};
export const deleteJob = (
  id: number,
  options?: SecondParameter<typeof http>
) => {
  return http<void>(
    { url: `/api/v1/scheduler/jobs/${id}`, method: "delete" },
    options
  );
};

export type DeleteJobMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteJob>>
>;

export type DeleteJobMutationError = unknown;

export const useDeleteJob = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteJob>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteJob>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteJob(id, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteJob>>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions);
};
export const configureJob = (
  id: string,
  configureSchedulerJobDto: ConfigureSchedulerJobDto,
  options?: SecondParameter<typeof http>
) => {
  return http<SchedulerJobDto>(
    {
      url: `/api/v1/scheduler/jobs/${id}/configure`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: configureSchedulerJobDto,
    },
    options
  );
};

export type ConfigureJobMutationResult = NonNullable<
  Awaited<ReturnType<typeof configureJob>>
>;
export type ConfigureJobMutationBody = ConfigureSchedulerJobDto;
export type ConfigureJobMutationError = unknown;

export const useConfigureJob = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof configureJob>>,
    TError,
    { id: string; data: ConfigureSchedulerJobDto },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof configureJob>>,
    { id: string; data: ConfigureSchedulerJobDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return configureJob(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof configureJob>>,
    TError,
    { id: string; data: ConfigureSchedulerJobDto },
    TContext
  >(mutationFn, mutationOptions);
};
