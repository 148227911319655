/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Dirigos API
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type { JobDto, GetJobsParams, KillJobParams } from "./model";
import { http } from "../http";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const getJobs = (
  params?: GetJobsParams,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<JobDto[]>(
    { url: `/api/v1/jobs`, method: "get", params, signal },
    options
  );
};

export const getGetJobsQueryKey = (params?: GetJobsParams) => [
  `/api/v1/jobs`,
  ...(params ? [params] : []),
];

export type GetJobsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getJobs>>
>;
export type GetJobsQueryError = unknown;

export const useGetJobs = <
  TData = Awaited<ReturnType<typeof getJobs>>,
  TError = unknown
>(
  params?: GetJobsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getJobs>>, TError, TData>;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetJobsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getJobs>>> = ({
    signal,
  }) => getJobs(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getJobs>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const killJob = (
  params?: KillJobParams,
  options?: SecondParameter<typeof http>
) => {
  return http<void>(
    { url: `/api/v1/jobs/kill`, method: "post", params },
    options
  );
};

export type KillJobMutationResult = NonNullable<
  Awaited<ReturnType<typeof killJob>>
>;

export type KillJobMutationError = unknown;

export const useKillJob = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof killJob>>,
    TError,
    { params?: KillJobParams },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof killJob>>,
    { params?: KillJobParams }
  > = (props) => {
    const { params } = props ?? {};

    return killJob(params, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof killJob>>,
    TError,
    { params?: KillJobParams },
    TContext
  >(mutationFn, mutationOptions);
};
