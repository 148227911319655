import React from "react";
import {Outlet, Route, Routes, useLocation} from "react-router-dom";
import {SfOrderQueue} from "./order-queue/SfOrderQueue";

export const SuperFaktura: React.FC = () => {
  return (
    <>
      <h1>SuperFaktura</h1>

      <Outlet />

      <Routes>
        <Route index element={<SfOrderQueue />} />
      </Routes>
    </>
  );
};
