import {Box, Tab, Tabs, Typography} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import React, {useState} from "react";
import {TypeMessageQueue} from "../queue/TypeMessageQueue";
import {Outbox} from "../outbox/Outbox";

type Props = {
  clientId: string;
  application: string;
}

export const Application: React.FC<Props> = ({
  clientId,
  application,
}) => {
  const [activeTab, setActiveTab] = useState("queues");

  return (
    <>
      <TabContext value={activeTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={activeTab}
            onChange={(_, value) => setActiveTab(value)}
          >
            <Tab label="Queues" value="queues"></Tab>
            <Tab label="Outbox" value="outbox"></Tab>
          </Tabs>
        </Box>

        <TabPanel value="queues" sx={{ px: 0 }}>
          <TypeMessageQueue
            clientId={clientId}
            application={application}
          />
        </TabPanel>

        <TabPanel value="outbox" sx={{ px: 0 }}>
          <Outbox
            clientId={clientId}
            application={application}
          />
        </TabPanel>
      </TabContext>
    </>
  );
};
