import React from "react";
import { Login } from "./user/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./Home";
import { AuthProvider, ProtectedRoute } from "./auth";
import { Clients } from "./clients/Clients";
import { NewClient } from "./clients/NewClient";
import { ClientDetail } from "./clients/ClientDetail";
import { createTheme, ThemeProvider } from "@mui/material";
import { ClientSettings } from "./clients/ClientSettings";
import { ClientConfig } from "./clients/ClientConfig";
import { Users } from "./users/Users";
import { UserDetail } from "./users/UserDetail";
import { NewUser } from "./users/NewUser";
import { User } from "./user/User";
import { UserChangePassword } from "./user/UserChangePassword";
import { Mapping } from "./mapping/Mapping";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { csCZ } from "@mui/material/locale";
import { AdminLayout } from "./admin/AdminLayout";
import { ClientManager } from "./clients/ClientManager";
import { ClientUsers } from "./clients/ClientUsers";
import { ClientScheduler } from "./clients/ClientScheduler";
import {ClientService} from "./clients/ClientService";
import {Jobs} from "./jobs/Jobs";
import {JobsOverview} from "./jobs/JobsOverview";
import {ClientStorageInfo} from "./clients/ClientStorageInfo";
import {ClientCircuitBreakers} from "./clients/ClientCircuitBreakers";
import {COLORS} from "./theme";
import {PaletteColor} from "@mui/material/styles/createPalette";

let theme = createTheme(
  {
    palette: {
      mode: 'light',
      primary: {
        main: '#141412',
      },
      secondary: {
        main: '#80c41d',
      },
    },
    components: {
      MuiLink: {
        styleOverrides: {
          root: ({ theme, ownerState }) => ({
            color: theme.palette.secondary.dark,
            textDecorationColor: theme.palette.secondary.dark,
            '&:hover': {
              textDecoration: 'none',
            },
          })
        }
      }
    }
  },
);

theme = createTheme(theme, {
  palette: COLORS.reduce((acc, color) => {
    acc[color.name] = theme.palette.augmentColor({
      color: {
        main: color.color,
      },
    });
    return acc;
  }, {} as Record<string, PaletteColor>)
})

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              <Route path="login" element={<Login />} />
              <Route path="/" element={<ProtectedRoute />}>
                <Route index element={<Home />}></Route>
                <Route path="user" element={<User />}>
                  <Route
                    path="change-password"
                    element={<UserChangePassword />}
                  />
                </Route>

                <Route path="manage/:clientId" element={<ClientManager />}>
                  <Route path=":service/*" element={<ClientService />} />
                </Route>

                <Route element={<AdminLayout />}>
                  <Route path="clients/:clientId" element={<ClientDetail />}>
                    <Route index element={<ClientSettings />} />
                    <Route path="config" element={<ClientConfig />} />
                    <Route path="users" element={<ClientUsers />} />
                    <Route path="scheduler" element={<ClientScheduler />} />
                    <Route path="storage" element={<ClientStorageInfo />} />
                    <Route path="circuit-breakers" element={<ClientCircuitBreakers />} />
                  </Route>

                  <Route path="jobs" element={<Jobs />}>
                    <Route index element={<JobsOverview />} />
                  </Route>

                  <Route path="users" element={<Users />} />
                  <Route path="users/:id" element={<UserDetail />} />
                  <Route path="users/new" element={<NewUser />} />

                  <Route path="mapping" element={<Mapping />} />
                  <Route path="clients" element={<Clients />} />
                  <Route path="clients/new" element={<NewClient />} />
                </Route>
              </Route>
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
