import {Box, Button, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import React from "react";
import {JobDto} from "../api-client/model";
import {Link} from "@mui/material";
import {Link as LinkRouter} from "react-router-dom";
import {CheckCircleOutline, ErrorOutline} from "@mui/icons-material";
import {ElapsedTime} from "../utils/elapsed";
import {dateTimeFormat} from "../utils/date";
import {ConfirmDialog} from "../ui/ConfirmDialog";
import {useKillJob} from "../api-client/jobs";

type Props = {
  jobs: JobDto[];
  showPid?: boolean;
}

export const JobsList: React.FC<Props> = ({ jobs, showPid }) => {
  const [jobToTerminate, setJobToTerminate] = React.useState<JobDto | null>(null);

  const terminateMutation = useKillJob();

  async function terminate(jobToTerminate: JobDto) {
    setJobToTerminate(null);
    await terminateMutation.mutateAsync({ params: { jobId: jobToTerminate.id } });
  }

  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell width="1%" />
            <TableCell>ID</TableCell>
            <TableCell>Client</TableCell>
            <TableCell>Job</TableCell>
            {showPid && <TableCell>PID</TableCell>}
            <TableCell>Started</TableCell>
            <TableCell align="right">Elapsed</TableCell>
            {showPid && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {jobs.map(job => (
            <TableRow key={job.id} hover>
              <TableCell>
                <Box sx={{ display: 'flex' }}>
                  {!job.finishedAt && <CircularProgress size={16} />}
                  {job.failed && <ErrorOutline color="error" />}
                  {job.finishedAt && !job.failed && <CheckCircleOutline color="success" />}
                </Box>
              </TableCell>
              <TableCell>{job.id}</TableCell>
              <TableCell>
                <Link
                  component={LinkRouter}
                  to={`/clients/${job.client.id}`}
                  color="secondary"
                >{job.client.name}</Link>
              </TableCell>
              <TableCell>
                <Link
                  component={LinkRouter}
                  to={`/clients/${job.client.id}/scheduler`}
                  color="secondary"
                >{job.schedulerJob?.name}</Link>
              </TableCell>
              {showPid && <TableCell>{job.pid}</TableCell>}
              <TableCell>{dateTimeFormat(job.startedAt)}</TableCell>
              <TableCell align="right">
                <ElapsedTime
                  startDate={job.startedAt}
                  endDate={job.finishedAt}
                />
              </TableCell>
              {showPid && (
                <TableCell sx={{ py: 0, width: 85 }}>
                  <Button variant="text" size="small" color="error" onClick={() => setJobToTerminate(job)}>Terminate</Button>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <ConfirmDialog
        open={jobToTerminate != null}
        onCancel={() => setJobToTerminate(null)}
        onConfirm={() => terminate(jobToTerminate!)}
        confirmLabel="Terminate"
        danger
        title="Terminate job?"
      >Job {jobToTerminate?.id} will be termined.</ConfirmDialog>
    </>
  );
};
