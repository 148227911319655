/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Dirigos API
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  LoginResultDto,
  LoginDto,
  UserDto,
  UserCreateDto,
  UserUpdateDto,
} from "./model";
import { http } from "../http";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const login = (
  loginDto: LoginDto,
  options?: SecondParameter<typeof http>
) => {
  return http<LoginResultDto>(
    {
      url: `/api/v1/users/login`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: loginDto,
    },
    options
  );
};

export type LoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof login>>
>;
export type LoginMutationBody = LoginDto;
export type LoginMutationError = unknown;

export const useLogin = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof login>>,
    TError,
    { data: LoginDto },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof login>>,
    { data: LoginDto }
  > = (props) => {
    const { data } = props ?? {};

    return login(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof login>>,
    TError,
    { data: LoginDto },
    TContext
  >(mutationFn, mutationOptions);
};
export const logout = (options?: SecondParameter<typeof http>) => {
  return http<void>({ url: `/api/v1/users/logout`, method: "post" }, options);
};

export type LogoutMutationResult = NonNullable<
  Awaited<ReturnType<typeof logout>>
>;

export type LogoutMutationError = unknown;

export const useLogout = <
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof logout>>,
    TError,
    TVariables,
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof logout>>,
    TVariables
  > = () => {
    return logout(requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof logout>>,
    TError,
    TVariables,
    TContext
  >(mutationFn, mutationOptions);
};
export const getUsers = (
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<UserDto[]>(
    { url: `/api/v1/users`, method: "get", signal },
    options
  );
};

export const getGetUsersQueryKey = () => [`/api/v1/users`];

export type GetUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUsers>>
>;
export type GetUsersQueryError = unknown;

export const useGetUsers = <
  TData = Awaited<ReturnType<typeof getUsers>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>;
  request?: SecondParameter<typeof http>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUsersQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsers>>> = ({
    signal,
  }) => getUsers(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUsers>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const createUser = (
  userCreateDto: UserCreateDto,
  options?: SecondParameter<typeof http>
) => {
  return http<UserDto>(
    {
      url: `/api/v1/users`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: userCreateDto,
    },
    options
  );
};

export type CreateUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof createUser>>
>;
export type CreateUserMutationBody = UserCreateDto;
export type CreateUserMutationError = unknown;

export const useCreateUser = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUser>>,
    TError,
    { data: UserCreateDto },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createUser>>,
    { data: UserCreateDto }
  > = (props) => {
    const { data } = props ?? {};

    return createUser(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof createUser>>,
    TError,
    { data: UserCreateDto },
    TContext
  >(mutationFn, mutationOptions);
};
export const getUser = (
  id: string,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<UserDto>(
    { url: `/api/v1/users/${id}`, method: "get", signal },
    options
  );
};

export const getGetUserQueryKey = (id: string) => [`/api/v1/users/${id}`];

export type GetUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUser>>
>;
export type GetUserQueryError = unknown;

export const useGetUser = <
  TData = Awaited<ReturnType<typeof getUser>>,
  TError = unknown
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUser>>> = ({
    signal,
  }) => getUser(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUser>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const updateUser = (
  id: string,
  userUpdateDto: UserUpdateDto,
  options?: SecondParameter<typeof http>
) => {
  return http<UserDto>(
    {
      url: `/api/v1/users/${id}`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: userUpdateDto,
    },
    options
  );
};

export type UpdateUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateUser>>
>;
export type UpdateUserMutationBody = UserUpdateDto;
export type UpdateUserMutationError = unknown;

export const useUpdateUser = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUser>>,
    TError,
    { id: string; data: UserUpdateDto },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUser>>,
    { id: string; data: UserUpdateDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateUser(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateUser>>,
    TError,
    { id: string; data: UserUpdateDto },
    TContext
  >(mutationFn, mutationOptions);
};
