import {Alert, Box, Paper, Typography} from "@mui/material";
import {JobsList} from "./JobsList";
import React from "react";
import {useGetJobs} from "../api-client/jobs";

type Props = {
  schedulerJobId?: number;
}

export const HistoryJobs: React.FC<Props> = ({ schedulerJobId }) => {
  const jobsQuery = useGetJobs({ running: false, schedulerJobId }, {
    query: {
      refetchInterval: 5000
    }
  });
  const jobs = jobsQuery.data || [];

  return (
    <Paper>
      <Box sx={{ p: 3 }}>
        <Typography variant="h5">History</Typography>

        {jobs.length === 0 && <Alert severity="info" sx={{ mt: 3 }}>No history job found</Alert>}
      </Box>

      {jobs.length > 0 && <JobsList jobs={jobs} />}
    </Paper>
  );
};
