import {Box} from "@mui/material";
import React from "react";
import {ClientDto} from "../api-client/model";

type Props = {
  client: ClientDto;
  isActive?: boolean;
  outline?: boolean;
  size?: number;
};

export const ClientIcon: React.FC<Props> = ({ client, isActive, outline, size }) => {
  const color = client.color;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: size ? 0 : 1,
        width: size ?? 40,
        height: size ?? 40,
        fontSize: size ? size / 2.2 : 16,
        lineHeight: 'normal',
        cursor: "pointer",
        backgroundColor: isActive ? `${color}.light` : `${color}.dark`,
        color: `${color}.contrastText`,
        borderRadius: 1,
        fontWeight: "bold",
        outline: outline ? 2 : 0,
        outlineColor: `${color}.dark`,
        textAlign: 'center',
        opacity: isActive ? 1 : 0.75,
        filter: isActive ? 'none' : 'grayscale(100%)',
        '&:hover': {
          opacity: 1,
          backgroundColor: `${color}.light`,
          filter: 'none'
        }
      }}
    >
      {client.name.substring(0, 2).toUpperCase()}
    </Box>
  );
}
