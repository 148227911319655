import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  getGetClientQueryKey, getGetClientsQueryKey,
  useDeleteClient,
  useGetAvailableClientConfigs,
  useGetClient,
  useUpdateClient,
} from "../api-client/clients";
import {ConfirmDialog} from "../ui/ConfirmDialog";
import {COLORS} from "../theme";
import {useQueryClient} from "@tanstack/react-query";

export function ClientSettings() {
  const queryClient = useQueryClient();
  const params = useParams();
  const navigate = useNavigate();
  const clientId = params.clientId as string;
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  // queries
  const clientQuery = useGetClient(clientId);
  const client = clientQuery.data;
  const availableConfigsQuery = useGetAvailableClientConfigs();
  const availableConfigs = availableConfigsQuery.data || [];

  const [configPath, setConfigPath] = useState<string>(
    client?.configPath ?? ""
  );
  const [code, setCode] = useState<string>(client?.code ?? "");
  const [name, setName] = useState<string>(client?.name ?? "");
  const [color, setColor] = useState<string>(client?.color ?? "");
  const [description, setDescription] = useState<string>(
    client?.description ?? ""
  );

  // mutation
  const updateClientMutation = useUpdateClient();
  const deleteClientMutation = useDeleteClient();

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    await updateClientMutation.mutateAsync({
      id: clientId,
      data: {
        code,
        name,
        color,
        description,
        configPath,
      },
    });

    await queryClient.invalidateQueries(getGetClientQueryKey(clientId));
    await queryClient.invalidateQueries(getGetClientsQueryKey());
  }

  async function handleDelete() {
    await deleteClientMutation.mutateAsync({ id: clientId });
    navigate("/clients");
  }

  return (
    <>
      <ConfirmDialog
        open={showDeleteDialog}
        onCancel={() => setShowDeleteDialog(false)}
        onConfirm={handleDelete}
        confirmLabel="Delete"
        title="Confirm deletion"
        danger
      >
        Are you sure you want to delete this client?
      </ConfirmDialog>

      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ display: "flex", flexDirection: "column", p: 3 }}
      >
        <TextField
          label="Code"
          required
          variant="outlined"
          size="small"
          fullWidth
          value={code}
          onChange={(e) => setCode(e.target.value)}
          disabled
          autoComplete="off"
          sx={{ mb: 3 }}
        />

        <FormControl size="small" required fullWidth sx={{ mb: 3 }}>
          <InputLabel>Config</InputLabel>
          <Select
            value={configPath}
            label="Config"
            onChange={(e) => setConfigPath(e.target.value)}
          >
            {configPath ? (
              <MenuItem value={configPath}>{configPath}</MenuItem>
            ) : null}
            {availableConfigs.map((path) => (
              <MenuItem key={path} value={path}>
                {path}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="Name"
          required
          variant="outlined"
          size="small"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          autoComplete="off"
          sx={{ mb: 3 }}
        />

        <TextField
          label="Description"
          variant="outlined"
          size="small"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          autoComplete="off"
          multiline
          minRows={2}
          sx={{ mb: 3 }}
        />

        <Select
          label="Color"
          variant="outlined"
          size="small"
          fullWidth
          value={color}
          onChange={(e) => setColor(e.target.value)}
          autoComplete="off"
          sx={{ mb: 3 }}
        >
          {COLORS.map((color) => (
            <MenuItem key={color.name} value={color.name}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: 20, height: 20, background: color.color, mr: 2 }}></Box>
                {color.name}
              </Box>
            </MenuItem>
          ))}
        </Select>

        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Button type="submit" variant="contained">
            Save
          </Button>
          <Button
            color="error"
            sx={{ ml: 3 }}
            onClick={() => setShowDeleteDialog(true)}
          >
            Delete
          </Button>
        </Box>
      </Box>
    </>
  );
}
