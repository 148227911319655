import {useParams} from "react-router-dom";
import {Application} from "../common/application/Application";
import {Typography} from "@mui/material";

type Props = {
    appName: string;
};

export function Emorfic({ appName }: Props) {
  const params = useParams<{ clientId: string }>();
  const clientId = params.clientId as string;

  return (
      <>
        <Typography variant="h4" gutterBottom>Emorfic</Typography>

        <Application
          clientId={clientId}
          application={appName}
        />
      </>
  );
}
