import cronstrue from "cronstrue";

export function cronDescription(cron: string): string {
    try {
        return cronstrue.toString(cron, {
            use24HourTimeFormat: true,
        });
    } catch {
        return cron ? 'Invalid cron expression' : '';
    }
}
