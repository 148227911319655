import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  GetOrionEdiPackageQueueMessagesParams,
  OrionEdiPackageQueueMessageDto,
} from "../../../api-client/model";
import { DEFAULT_FILTER, MessageQueue } from "../../common/queue/MessageQueue";
import {
  useGetOrionEdiPackageQueueMessages,
  useOrionEdiPackageRetryMessage,
} from "../../../api-client/orion-edi";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import xmlFormat from "xml-formatter";

export const OrionPackageQueue: React.FC = () => {
  const params = useParams<{ clientId: string }>();
  const clientId = params.clientId as string;

  const [showXml, setShowXml] = useState<OrionEdiPackageQueueMessageDto | null>(
    null
  );

  const [filter, setFilter] =
    useState<GetOrionEdiPackageQueueMessagesParams>(DEFAULT_FILTER);
  const queueQuery = useGetOrionEdiPackageQueueMessages(
    params.clientId!,
    filter
  );

  const retryMutation = useOrionEdiPackageRetryMessage();

  function retry(message: OrionEdiPackageQueueMessageDto) {
    return retryMutation.mutateAsync({
      client: clientId,
      messageId: message.id,
    });
  }

  return (
    <>
      <Dialog open={showXml != null}>
        <DialogTitle>XML</DialogTitle>
        <DialogContent>
          {showXml?.message && (
            <pre>
              <code style={{ fontSize: 12 }}>
                {xmlFormat(showXml.message, { collapseContent: true })}
              </code>
            </pre>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowXml(null)}>Zavřít</Button>
        </DialogActions>
      </Dialog>

      <MessageQueue
        clientId={clientId}
        messages={queueQuery.data?.items ?? []}
        refetch={queueQuery.refetch}
        isLoading={queueQuery.isLoading}
        count={queueQuery.data?.total}
        retryMessage={retry}
        filter={filter}
        onFilter={(filter) =>
          setFilter((current) => ({ ...current, ...filter }))
        }
        extraColumns={[
          {
            name: "XML",
            render: (x) =>
              x.message && (
                <Button size="small" onClick={() => setShowXml(x)}>
                  zobrazit
                </Button>
              ),
            sx: { py: 0 },
          },
        ]}
      ></MessageQueue>
    </>
  );
};
