import {Box, Dialog, DialogContent, DialogTitle} from "@mui/material";
import React from "react";
import {useCreateJob} from "../api-client/scheduler";
import {SchedulerJobForm, SchedulerJobFormValue} from "./SchedulerJobForm";

type Props = {
  open: boolean;
  onClose: () => void;
  clientId: string;
  onCreated: () => void;
};

export const CreateSchedulerJob: React.FC<Props> = ({ clientId, open, onClose, onCreated }) => {

  const createMutation = useCreateJob();

  async function create(data: SchedulerJobFormValue) {
    await createMutation.mutateAsync({
      data: {
        clientId,
        ...data
      }
    });

    onClose();
    onCreated();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Box>
        <DialogTitle>Create scheduler job</DialogTitle>
        <DialogContent>
          <SchedulerJobForm onSubmit={create} />
        </DialogContent>
      </Box>
    </Dialog>
  )
};
