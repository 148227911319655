import Axios, { AxiosError, AxiosRequestConfig } from "axios";
export const ApiHttpClient = Axios.create({ baseURL: window.location.origin });

ApiHttpClient.interceptors.response.use(
  (v) => v,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
  }
);

export const http = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  return ApiHttpClient({
    ...config,
    ...options,
  }).then((x) => x.data);
};
