import React, { useEffect } from "react";
import { useAuthentication } from "../auth";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Apps, Person } from "@mui/icons-material";
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

export const AdminLayout: React.FC = () => {
  const { isSuperAdmin } = useAuthentication();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isSuperAdmin) {
      navigate("/");
    }
  }, [isSuperAdmin, navigate]);

  if (!isSuperAdmin) {
    return null;
  }

  return (
    <Box
      sx={{ display: "flex", backgroundColor: "#fff", flex: 1, height: "100%" }}
    >
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.grey[100],
          flexGrow: 1,
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
