/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Dirigos API
 * OpenAPI spec version: 1.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type { TypeQueueDto, GetQueueParams } from "./model";
import { http } from "../http";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const getQueue = (
  client: string,
  service: string,
  params?: GetQueueParams,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<TypeQueueDto>(
    {
      url: `/api/v1/clients/${client}/${service}/queues`,
      method: "get",
      params,
      signal,
    },
    options
  );
};

export const getGetQueueQueryKey = (
  client: string,
  service: string,
  params?: GetQueueParams
) => [
  `/api/v1/clients/${client}/${service}/queues`,
  ...(params ? [params] : []),
];

export type GetQueueQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQueue>>
>;
export type GetQueueQueryError = unknown;

export const useGetQueue = <
  TData = Awaited<ReturnType<typeof getQueue>>,
  TError = unknown
>(
  client: string,
  service: string,
  params?: GetQueueParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getQueue>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetQueueQueryKey(client, service, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getQueue>>> = ({
    signal,
  }) => getQueue(client, service, params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getQueue>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!(client && service), ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
