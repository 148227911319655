import {Outbox} from "../common/outbox/Outbox";
import {useParams} from "react-router-dom";

export function Navision() {
    const params = useParams<{ clientId: string }>();
    const clientId = params.clientId as string;

    return (
        <>
            <h1>Navision</h1>

            <Outbox clientId={clientId} application="navision" />
        </>
    );
}
