import React from "react";
import { useParams } from "react-router-dom";
import Editor from "@monaco-editor/react";
import { Container, Paper, Typography } from "@mui/material";
import { useGetClientConfig } from "../api-client/clients";

export function ClientConfig() {
  const params = useParams();
  const clientId = params.clientId as string;

  const configQuery = useGetClientConfig(clientId);
  const config = configQuery.data?.config;

  return (
    <>
      {config ? (
        <Paper variant="outlined">
          <Editor
            height="500px"
            language="yaml"
            value={config}
            options={{
              minimap: {
                enabled: false,
              },
            }}
          />
        </Paper>
      ) : null}
    </>
  );
}
