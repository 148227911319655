import {
  Box,
} from "@mui/material";
import React from "react";
import {RunningJobs} from "./RunningJobs";
import {HistoryJobs} from "./HistoryJobs";
import {useSearchParams} from "react-router-dom";

export const JobsOverview = () => {
  const [params] = useSearchParams();
  const schedulerJobId = params.has('schedulerJobId') ? parseInt(params.get('schedulerJobId')!) : undefined;

  return (
    <>
      <Box sx={{ mb: 2}}>
        <RunningJobs schedulerJobId={schedulerJobId} />
      </Box>

      <HistoryJobs schedulerJobId={schedulerJobId} />
    </>
  )
};
