import React from "react";
import {Alert, Box, Button, Container, Paper, Tab, Tabs, Typography} from "@mui/material";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { useGetClient } from "../api-client/clients";
import {ClientDto} from "../api-client/model";
import {ClientIcon} from "../ui/ClientIcon";

const MENU = [
  { link: ".", label: "Settings" },
  { link: "config", label: "Config" },
  { link: "users", label: "Users" },
  { link: "scheduler", label: "Scheduler" },
  { link: "storage", label: "Storage" },
  { link: "circuit-breakers", label: "Circuit Breakers" },
];

export type ClientCtx = {
  client: ClientDto | null;
  onSchedulerToggle: () => Promise<unknown>;
}

export function ClientDetail() {
  const { pathname } = useLocation();
  const lastSegment = pathname.split("/").pop();
  const params = useParams();
  const clientId = params.clientId as string;
  const clientQuery = useGetClient(clientId);
  const client = clientQuery.data;

  function onSchedulerToggle() {
    return clientQuery.refetch();
  }

  return (
    <Container sx={{ pt: 5 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {client && (
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 3, gap: 2 }}>
            <ClientIcon client={client} isActive />

            <Typography variant="h4">
              {client.name}
            </Typography>
          </Box>
        )}

        <Link to={`/manage/${client?.id}`}>
          <Button variant="outlined" size="small">Manage</Button>
        </Link>
      </Box>

      {client && (
        <Paper>
          <Box sx={{ px: 3, pt: 3 }}>
            {!client.schedulerEnabled && <Alert severity="warning" sx={{ mb: 1 }}>Scheduler is disabled</Alert>}

            <Box sx={{ mb: 1 }}>
              <Tabs value={lastSegment === client?.id ? "." : lastSegment}>
                {MENU.map((item) => (
                  <Tab
                    label={item.label}
                    to={item.link}
                    key={item.link}
                    value={item.link}
                    component={Link}
                  />
                ))}
              </Tabs>
            </Box>
          </Box>

          <Outlet context={{ client, onSchedulerToggle } as ClientCtx} />
        </Paper>
      )}
    </Container>
  );
}
