import {Box, Tooltip} from "@mui/material";
import {useGetClients} from "../api-client/clients";
import {NavLink} from "react-router-dom";
import {ClientIcon} from "../ui/ClientIcon";

export const QuickClientSelect = () => {
  const clientsQuery = useGetClients({
    onlyUsers: true
  });
  const colors = ['teal', 'cyan', 'amber', 'red', 'pink'];

  return (
    <Box component="aside" sx={{ backgroundColor: "primary.main" }}>
      {clientsQuery.data?.map(((client, i) => {
        return (
          <NavLink to={`/manage/${client.id}`} key={client.id} style={{textDecoration: "none"}}>
            {({isActive}) => (
              <Tooltip title={client.name} key={client.id} placement="right">
                <Box sx={{ m: 1 }}>
                  <ClientIcon client={client} isActive={isActive} outline={isActive} />
                </Box>
              </Tooltip>
            )}
          </NavLink>
        );
      }))}
    </Box>
  )
};
