import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

interface UserData {
  sub: number;
  username: string;
  name: string;
  exp: number;
  sa: boolean;
}

interface AuthContextModel {
  isAuthenticated: boolean;
  user: UserData | null;
  refresh: () => void;
}

const AuthContext = createContext<AuthContextModel>({
  isAuthenticated: false,
  user: null,
  refresh: () => {},
});

export const AuthProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [user, setUser] = useState<UserData | null>(getJwtPayload());

  const refresh = useCallback(() => {
    setUser(getJwtPayload());
  }, [setUser]);

  const auth: AuthContextModel = {
    isAuthenticated: (user?.exp ?? 0) > Date.now() / 1000,
    user,
    refresh,
  };

  useEffect(() => {
    const id = setInterval(() => setUser(getJwtPayload()), 60_000);

    return () => clearInterval(id);
  }, [setUser]);

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export function useAuthentication() {
  const auth = useContext(AuthContext);

  return {
    isAuthenticated: auth.isAuthenticated,
    user: auth.user,
    isSuperAdmin: auth.user?.sa ?? false,
    refresh: auth.refresh,
  };
}

function getJwtPayload(): UserData | null {
  const cookies = document.cookie.split(";");

  for (const cookie of cookies) {
    const [name, value] = cookie.split("=");

    if (name === "payload") {
      const [header, payload] = value.split(".");
      return JSON.parse(atob(payload));
    }
  }

  return null;
}
