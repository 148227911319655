import { Container, Paper, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { UserForm, UserFormValue, UserLevel } from "./UserForm";
import { useGetUser, useUpdateUser } from "../api-client/users";

export function UserDetail() {
  const params = useParams();
  const id = params.id as string;

  // queries
  const getUserQuery = useGetUser(id);
  const user = getUserQuery.data;

  // mutation
  const updateUserMutation = useUpdateUser({
    mutation: {
      onSuccess: () => getUserQuery.refetch(),
    },
  });

  function handleSubmit({ password, level, ...data }: UserFormValue) {
    updateUserMutation.mutate({
      id,
      data: {
        ...data,
        superAdmin: level === UserLevel.SuperAdmin,
        techUser: level === UserLevel.TechUser
      }
    });
  }

  return (
    <Container sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        {user?.username}
      </Typography>

      <Paper sx={{ p: 3 }}>
        <UserForm user={user} onSubmit={handleSubmit} />
      </Paper>
    </Container>
  );
}
